import { ChangeDetectorRef, Component, OnInit, Renderer2 } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { LoginService } from "src/app/services/login.service";
import { JobsService } from "src/app/services/jobs.service";
import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog, MatSnackBar, } from "@angular/material";


@Component({
  selector: 'app-group-services',
  templateUrl: './group-services.component.html',
  styleUrls: ['./group-services.component.css']
})
export class GroupServicesComponent implements OnInit {
  user: any;
  menuList: Array<any> = [];
  addList: Array<any> = [];
  addvatiseList: Array<any> = [];
  isSideNavOpen: boolean = false;
  baseUrl: string = "";
  currentPath: string = "Android";
  loggedIn: boolean;
  INRValue: number = 1;
  minPrice: number = 10;
  category: any = [];
  categoryRes: any;
  usercurrencyObj: any;
  dollerChangeValue: string = "USD";
  centralAsiaSubcountries: Array<any> = [];
  eastAsiaSubcountries: Array<any> = [];
  showMenu = false;
  isMainAdmin: boolean = false;
  isDropdownOpen = false;
  isDropdownOpen1 = false;
  isCentralAsiaOpen: boolean = false;
  dropdownMargin: number = 0;
  asia_Countries: Array<any> = [];
  asia_Countries1: Array<any> = [];
  africa_Countries: Array<any> = [];
  africa_Countries1: Array<any> = [];
  northAmerica_Countries: Array<any> = [];
  northAmerica_Countries1: Array<any> = [];
  southAmerica_Countries: Array<any> = [];
  southAmerica_Countries1: Array<any> = [];
  antarctica_Countries: Array<any> = [];
  antarctica_Countries1: Array<any> = [];
  europe_Countries: Array<any> = [];
  europe_Countries1: Array<any> = [];
  australia_Countries: Array<any> = [];
  australia_Countries1: Array<any> = [];

  isAsiaOpen = false;
  isAfricaOpen = false;
  isNorthAmeOpen = false;
  isSouthAmeOpen = false
  isAntarctiOpen = false;
  isEuropeOpen = false;
  isAustraliaOpen = false;
  loading: boolean = false;
  highlightedCountry: string | null = null;
  showDetails: boolean = false;
  routeSub: any;
  isSelected: boolean = false;
  
  constructor(private loginService: LoginService,
    private router: Router, private jobsService: JobsService,
    private snackBar: MatSnackBar, private renderer: Renderer2, private cdr: ChangeDetectorRef,
    private activatedRoute: ActivatedRoute,
   ) { }

  ngOnInit() {
    // this.onLoad();
    this.user = JSON.parse(localStorage.getItem("user"));
    this.loggedIn = this.user && this.user.userID ? true : false;

    this.browseCategory();
    console.log("!@#$%%%%")
    this.router.navigateByUrl('/234_CountriesInformation/country/India');
   
  }

  openSideNav() {
    this.isSideNavOpen = !this.isSideNavOpen;
  }


  browseCategory() {
    this.menuList = [
      { "categoryName": "Asia" },
      { "categoryName": "Africa" },
      { "categoryName": "North America" },
      { "categoryName": "South America" },
      { "categoryName": "Antarctica" },
      { "categoryName": "Europe" },
      { "categoryName": "Australia" }
    ];
    // this.asia_Countries1 = [
    //   { "cname": "Central Asia","url": "" },
    //   { "cname": "Kazakhstan", "url": "https://onedrive.live.com/embed?resid=6CFAFA09C77E5881%21888&authkey=!ABHcXU3T6FUeDgA&em=2" },
    //   {"cname": "Kyrgyzsta", "url": "https://onedrive.live.com/embed?resid=6CFAFA09C77E5881%21889&authkey=!ABW5JFxSxy_rc2Y&em=2" },
    //   { "cname": "Tajikistan", "url": "https://onedrive.live.com/embed?resid=6CFAFA09C77E5881%21902&authkey=!AIH-jL7WvFhvxic&em=2" },
    //   { "cname": "Uzbekistan", "url": "https://onedrive.live.com/embed?resid=6CFAFA09C77E5881%21910&authkey=!AOw8GCqtLabYMpg&em=2" },
    //   { "cname": "East Asia", "url": ""},
    //   { "cname": "China", "url": "https://onedrive.live.com/embed?resid=6CFAFA09C77E5881%21881&authkey=!ADT6V2Ta_jhpgkY&em=2" },
    //   { "cname": "Japan",  "url": "https://onedrive.live.com/embed?resid=6CFAFA09C77E5881%21887&authkey=!AK7TcmYyAIv5OOs&em=2" },
    //   { "cname": "North Korea",  "url": "https://onedrive.live.com/embed?resid=6CFAFA09C77E5881%21748&authkey=!AE5QkkSTnCaITpI&em=2"  } ,
    //   { "cname": "South Korea",  "url": "https://onedrive.live.com/embed?resid=6CFAFA09C77E5881%21908&authkey=!AOkGJN091PnG3p0&em=2"  },
    //   {"cname": "Mongolia", "url": "https://onedrive.live.com/embed?resid=6CFAFA09C77E5881%21894&authkey=!AA-R46s0SgnrBTU&em=2" },
    //   { "cname": "Taiwan", "url": "https://onedrive.live.com/embed?resid=6CFAFA09C77E5881%21901&authkey=!AMk5bQ0eidvgUE4&em=2" } ,
    //   { "cname": "North Asia", "url": "" },
    //   { "cname": "Russia", "url": "https://onedrive.live.com/embed?resid=6CFAFA09C77E5881%21933&authkey=!AD4mkFi-1HAYYwU&em=2" },
    //   { "cname": "South Asia", "url": "" },
    //   { "cname": "Afghanistan", "url": "https://onedrive.live.com/embed?resid=6CFAFA09C77E5881%21876&authkey=!AGg0hOcRhq7RrHI&em=2" },
    //   { "cname": "Bangladesh", "url": "" },
    //   { "cname": "Bhutan", "url": "" },
    //   { "cname": "India", "url": "" },
    //   { "cname": "Iran", "url": "" },
    //   { "cname": "Maldives", "url": "" },
    //   { "cname": "Nepal", "url": "" },
    //   { "cname": "Pakistan", "url": "" },
    //   { "cname": "Sri Lanka", "url": "" },
    //   { "cname": "Southeast Asia", "url": "" },
    //   { "cname": "Brunei", "url": "" },
    //   { "cname": "Cambodia", "url": "" },
    //   { "cname": "East Timor", "url": "" },
    //   { "cname": "Indonesia", "url": "" },
    //   { "cname": "Laos", "url": "" },
    //   { "cname": "Malaysia", "url": "" },
    //   { "cname": "Myanmar", "url": "" },
    //   { "cname": "Philippines", "url": "" },
    //   { "cname": "Singapore", "url": "" },
    //   { "cname": "Thailand", "url": "" },
    //   { "cname": "Vietnam", "url": "" },
    //   { "cname": "West Asia", "url": "" },
    //   { "cname": "Armenia", "url": "" },
    //   { "cname": "Azerbaijan", "url": "" },
    //   { "cname": "Bahrain", "url": "" },
    //   { "cname": "Cyprus", "url": "" },
    //   { "cname": "Georgia", "url": "" },
    //   { "cname": "Iraq", "url": "" },
    //   { "cname": "Israel", "url": "" },
    //   { "cname": "Jordan", "url": "" },
    //   { "cname": "Kuwait", "url": "" },
    //   { "cname": "Lebanon", "url": "" },
    //   { "cname": "Oman", "url": "" },
    //   { "cname": "Qatar", "url": "" },
    //   { "cname": "Saudi Arabia", "url": "" },
    //   { "cname": "Syria", "url": "" },
    //   { "cname": "Turkey", "url": "" },
    //   { "cname": "United Arab Emirates", "url": "" },
    //   { "cname": "Yemen", "url": "" },
    //   { "cname": "Macao", "url": "" },
    //   { "cname": "British Indian Ocean Territory", "url": "" },
    //   { "cname": "Cocos Islands", "url": "" },
    //   { "cname": "Guam", "url": "" },
    //   { "cname": "Hong Kong" }
    // ]
    this.asia_Countries = [
      "Central Asia",
      "Kazakhstan",
      "Kyrgyzstan",
      "Tajikistan",
      "Uzbekistan",
      "East Asia",
      "China",
      "Japan",
      "North Korea",
      "South Korea",
      "Mongolia",
      "Taiwan",
      "North Asia",
      "Russia",
      "South Asia",
      "Afghanistan",
      "Bangladesh",
      "Bhutan",
      "India",
      "Iran",
      "Maldives",
      "Nepal",
      "Pakistan",
      "Sri Lanka",
      "Southeast Asia",
      "Brunei",
      "Cambodia",
      "East Timor",
      "Indonesia",
      "Laos",
      "Malaysia",
      "Myanmar",
      "Philippines",
      "Singapore",
      "Thailand",
      "Vietnam",
      "West Asia",
      "Armenia",
      "Azerbaijan",
      "Bahrain",
      "Cyprus",
      "Georgia",
      "Iraq",
      "Israel",
      "Jordan",
      "Kuwait",
      "Lebanon",
      "Oman",
      "Qatar",
      "Saudi Arabia",
      "Syria",
      "Turkey",
      "United Arab Emirates",
      "Yemen",
      "Macao",
      "British Indian Ocean Territory",
      "Cocos Islands",
      "Guam",
      "Hong Kong"
    ];

    // this.africa_Countries1 = [
    //   { "cname": "North Africa", "url": "" },
    //   { "cname": "Algeria", "url": "" },
    //   { "cname": "Egypt", "url": "" },
    //   { "cname": "Libya", "url": "" },
    //   { "cname": "Morocco", "url": "" },
    //   { "cname": "Sudan", "url": "" },
    //   { "cname": "Tunisia", "url": "" },
    //   { "cname": "Western Sahara", "url": "" },
    //   { "cname": "West Africa", "url": "" },
    //   { "cname": "Benin", "url": "" },
    //   { "cname": "Burkina Faso", "url": "" },
    //   { "cname": "Gambia", "url": "" },
    //   { "cname": "Ghana", "url": "" },
    //   { "cname": "Guinea", "url": "" },
    //   { "cname": "Guinea-Bissau", "url": "" },
    //   { "cname": "Liberia", "url": "" },
    //   { "cname": "Mali", "url": "" },
    //   { "cname": "Mauritania", "url": "" },
    //   { "cname": "Niger", "url": "" },
    //   { "cname": "Nigeria", "url": "" },
    //   { "cname": "Senegal", "url": "" },
    //   { "cname": "Sierra Leone", "url": "" },
    //   { "cname": "Togo", "url": "" },
    //   { "cname": "Central Africa", "url": "" },
    //   { "cname": "Angola", "url": "" },
    //   { "cname": "Cameroon", "url": "" },
    //   { "cname": "Central African Republic", "url": "" },
    //   { "cname": "Chad", "url": "" },
    //   { "cname": "Congo, Democratic Republic", "url" : "" },
    //   { "cname": "Republic of the Congo", "url": "" },
    //   { "cname": "Equatorial Guinea", "url": "" },
    //   { "cname": "Gabon", "url": "" },
    //   { "cname": "East Africa", "url": "" },
    //   { "cname": "Burundi", "url": "" },
    //   { "cname": "Comoros", "url": "" },
    //   { "cname": "Djibouti", "url": "" },
    //   { "cname": "Eritrea", "url": "" },
    //   { "cname": "Ethiopia", "url": "" },
    //   { "cname": "Kenya", "url": "" },
    //   { "cname": "Madagascar", "url": "" },
    //   { "cname": "Malawi", "url": "" },
    //   { "cname": "Mauritius", "url": "" },
    //   { "cname": "Mozambique", "url": "" },
    //   { "cname": "Rwanda", "url": "" },
    //   { "cname": "Seychelles", "url": "" },
    //   { "cname": "Somalia", "url": "" },
    //   { "cname": "South Sudan", "url": "" },
    //   { "cname": "Tanzania", "url": "" },
    //   { "cname": "Uganda", "url": "" },
    //   { "cname": "Southern Africa", "url": "" },
    //   { "cname": "Botswana", "url": "" },
    //   { "cname": "Eswatini", "url": "" },
    //   { "cname": "Lesotho", "url": "" },
    //   { "cname": "Namibia", "url": "" },
    //   { "cname": "South Africa", "url": "" },
    //   { "cname": "Zambia", "url": "" },
    //   { "cname": "Zimbabwe", "url": "" },
    //   { "cname": "Saint Helena", "url": "" },
    //   { "cname": "Ascension and Tristan da Cunha", "url": "" },
    //   { "cname": "Cameroon", "url": "" }
    // ]

    this.africa_Countries = [
      "North Africa",
      "Algeria",
      "Egypt",
      "Libya",
      "Morocco",
      "Sudan",
      "Tunisia",
      "Western Sahara",
      "West Africa",
      "Benin",
      "Burkina Faso",
      "Gambia",
      "Ghana",
      "Guinea",
      "Guinea-Bissau",
      "Liberia",
      "Mali",
      "Mauritania",
      "Niger",
      "Nigeria",
      "Senegal",
      "Sierra Leone",
      "Togo",
      "Central Africa",
      "Angola",
      "Cameroon",
      "Central African Republic",
      "Chad",
      "Congo, Democratic Republic",
      "Republic of the Congo",
      "Equatorial Guinea",
      "Gabon",
      "East Africa",
      "Burundi",
      "Comoros",
      "Djibouti",
      "Eritrea",
      "Ethiopia",
      "Kenya",
      "Madagascar",
      "Malawi",
      "Mauritius",
      "Mozambique",
      "Rwanda",
      "Seychelles",
      "Somalia",
      "South Sudan",
      "Tanzania",
      "Uganda",
      "Southern Africa",
      "Botswana",
      "Eswatini",
      "Lesotho",
      "Namibia",
      "South Africa",
      "Zambia",
      "Zimbabwe",
      "Saint Helena , Ascension and Tristan da Cunha",
      "Cameroon"
    ]


    // this.northAmerica_Countries1 = [
    //   { "cname": "Northern America", "url": "" },
    //   { "cname": "Canada", "url": "" },
    //   { "cname": "Saint Pierre and Miquelon (French overseas collectivity)", "url": "" },
    //   { "cname": "Bermuda", "url": "" },
    //   { "cname": "Central America", "url": "" },
    //   { "cname": "Belize", "url": "" },
    //   { "cname": "Costa Rica", "url": "" },
    //   { "cname": "El Salvador", "url": "" },
    //   { "cname": "Guatemala", "url": "" },
    //   { "cname": "Honduras", "url": "" },
    //   { "cname": "Nicaragua", "url": "" },
    //   { "cname": "Panama", "url": "" },
    //   { "cname": "Caribbean", "url": "" },
    //   { "cname": "Antigua and Barbuda", "url": "" },
    //   { "cname": "Barbados", "url": "" },
    //   { "cname": "Cuba", "url": "" },
    //   { "cname": "Dominica", "url": "" },
    //   { "cname": "Dominican Republic", "url": "" },
    //   { "cname": "Grenada", "url": "" },
    //   { "cname": "Haiti", "url": "" },
    //   { "cname": "Jamaica", "url": "" },
    //   { "cname": "Saint Kitts and Nevis", "url": "" },
    //   { "cname": "Saint Lucia", "url": "" },
    //   { "cname": "Saint Vincent and the Grenadines", "url": "" },
    //   { "cname": "Trinidad and Tobago", "url": "" },
    //   { "cname": "Narrow Definition", "url": "" },
    //   { "cname": "Mexico", "url": "" },
    //   { "cname": "United States of America", "url": "" },
    //   { "cname": "Sint Maarten", "url": "" },
    //   { "cname": "Turks and Caicos Islands", "url": "" },
    //   { "cname": "Puerto Rico", "url": "" },
    //   { "cname": "Montserrat", "url": "" },
    //   { "cname": "French Polynesia", "url": "" },
    //   { "cname": "Northern Mariana Islands", "url": "" },
    //   { "cname": "Anguilla", "url": "" },
    //   { "cname": "The Bahamas", "url": "" },
    //   { "cname": "Cayman Islands", "url": "" },
    //   { "cname": "Guadeloupe", "url": "" },
    //   { "cname": "British Virgin Islands", "url": "" },
    //   { "cname": "American Samoa", "url": "" },
    //   { "cname": "Aruba", "url": "" },
    //   { "cname": "Bonaire", "url": "" },
    //   { "cname": "Curacao", "url": "" },
    //   { "cname": "Saint Barthelemy", "url": "" },
    // ]

    this.northAmerica_Countries = [
      "Northern America",
      "Canada",
      "Saint Pierre and Miquelon",
      "Bermuda",
      "Central America",
      "Belize",
      "Costa Rica",
      "El Salvador",
      "Guatemala",
      "Honduras",
      "Nicaragua",
      "Panama",
      "Caribbean",
      "Antigua and Barbuda",
      "Barbados",
      "Cuba",
      "Dominica",
      "Dominican Republic",
      "Grenada",
      "Haiti",
      "Jamaica",
      "Saint Kitts and Nevis",
      "Saint Lucia",
      "Saint Vincent and the Grenadines",
      "Trinidad and Tobago",
      "Narrow Definition",
      "Mexico",
      "United States of America",
      "Sint Maarten",
      "Turks and Caicos Islands",
      "Puerto Rico",
      "Montserrat",
      "French Polynesia",
      "Northern Mariana Islands",
      "Anguilla",
      "The Bahamas",
      "Cayman Islands",
      "Guadeloupe",
      "British Virgin Islands",
      "American Samoa",
      "Aruba",
      "Bonaire",
      "Curacao",
      "Saint Barthelemy"
    ]

    // this.southAmerica_Countries1 = [
    //   { "cname" : "Argentina", "url" : "" },
    //   { "cname" : "Bolivia", "url" : "" },
    //   { "cname" : "Brazil", "url" : "" },
    //   { "cname" : "Chile", "url" : "" },
    //   { "cname" : "Colombia", "url" : "" },
    //   { "cname" : "Ecuador", "url" : "" },
    //   { "cname" : "Guyana", "url" : "" },
    //   { "cname" : "Paraguay", "url" : "" },
    //   { "cname" : "Peru", "url" : "" },
    //   { "cname" : "Suriname", "url" : "" },
    //   { "cname" : "Uruguay", "url" : "" },
    //   { "cname" : "Venezuela", "url" : "" },
    //   { "cname" : "Falkland Islands", "url" : "" },
    //   { "cname" : "Pitcairn Islands", "url" : "" },
    // ]


    this.southAmerica_Countries = [
      "Argentina",
      "Bolivia",
      "Brazil",
      "Chile",
      "Colombia",
      "Ecuador",
      "Guyana",
      "Paraguay",
      "Peru",
      "Suriname",
      "Uruguay",
      "Venezuela",
      "Falkland Islands",
      "Pitcairn Islands"
    ]


    // this.antarctica_Countries1 = [
    //   { "cname" : "French Southern and Antarctic Lands", "url" : "" },
    // ]
    this.antarctica_Countries = [
      "French Southern and Antarctic Lands"
    ]

    // this.europe_Countries1 = [
    //   { "cname" : "Albania", "url" : "" },
    //   { "cname" : "Andorra", "url" : "" },
    //   { "cname" : "Austria", "url" : "" },
    //   { "cname" : "Belarus", "url" : "" },
    //   { "cname" : "Belgium", "url" : "" },
    //   { "cname" : "Bosnia and Herzegovina", "url" : "" },
    //   { "cname" : "Bulgaria", "url" : "" },
    //   { "cname" : "Croatia", "url" : "" },
    //   { "cname" : "Czech", "url" : "" },
    //   { "cname" : "Denmark", "url" : "" },
    //   { "cname" : "Estonia", "url" : "" },
    //   { "cname" : "Finland", "url" : "" },
    //   { "cname" : "France", "url" : "" },
    //   { "cname" : "Germany", "url" : "" },
    //   { "cname" : "Greece", "url" : "" },
    //   { "cname" : "Hungary", "url" : "" },
    //   { "cname" : "Iceland", "url" : "" },
    //   { "cname" : "Italy", "url" : "" },
    //   { "cname" : "Kosovo", "url" : "" },
    //   { "cname" : "Latvia", "url" : "" },
    //   { "cname" : "Liechtenstein", "url" : "" },
    //   { "cname" : "Lithuania", "url" : "" },
    //   { "cname" : "Luxembourg", "url" : "" },
    //   { "cname" : "Malta", "url" : "" },
    //   { "cname" : "Moldova", "url" : "" },
    //   { "cname" : "Monaco", "url" : "" },
    //   { "cname" : "Montenegro", "url" : "" },
    //   { "cname" : "Netherlands", "url" : "" },
    //   { "cname" : "North Macedonia", "url" : "" },
    //   { "cname" : "Norway", "url" : "" },
    //   { "cname" : "Poland", "url" : "" },
    //   { "cname" : "Portugal", "url" : "" },
    //   { "cname" : "Romania", "url" : "" },
    //   { "cname" : "San Marino", "url" : "" },
    //   { "cname" : "Serbia", "url" : "" },
    //   { "cname" : "Slovakia", "url" : "" },
    //   { "cname" : "Slovenia", "url" : "" },
    //   { "cname" : "Spain", "url" : "" },
    //   { "cname" : "Sweden", "url" : "" },
    //   { "cname" : "Switzerland", "url" : "" },
    //   { "cname" : "Ukraine", "url" : "" },
    //   { "cname" : "United Kingdom", "url" : "" },
    //   { "cname" : "Gibraltar", "url" : "" },
    //   { "cname" : "Faroe Islands", "url" : "" },
    //   { "cname" : "Greenland", "url" : "" },
    //   { "cname" : "Scotland", "url" : "" },
    //   { "cname" : "Republic of Ireland", "url" : "" },
    //   { "cname" : "Vatican City", "url" : "" },
    // ]
    this.europe_Countries = [
      "Albania",
      "Andorra",
      "Austria",
      "Belarus",
      "Belgium",
      "Bosnia and Herzegovina",
      "Bulgaria",
      "Croatia",
      "Czech",
      "Denmark",
      "Estonia",
      "Finland",
      "France",
      "Germany",
      "Greece",
      "Hungary",
      "Iceland",
      "Italy",
      "Kosovo",
      "Latvia",
      "Liechtenstein",
      "Lithuania",
      "Luxembourg",
      "Malta",
      "Moldova",
      "Monaco",
      "Montenegro",
      "Netherlands",
      "North Macedonia",
      "Norway",
      "Poland",
      "Portugal",
      "Romania",
      "San Marino",
      "Serbia",
      "Slovakia",
      "Slovenia",
      "Spain",
      "Sweden",
      "Switzerland",
      "Ukraine",
      "United Kingdom",
      "Gibraltar",
      "Faroe Islands",
      "Greenland",
      "Scotland",
      "Republic of Ireland",
      "Vatican City"
    ]
    //     this.australia_Countries1 = [
    //       { "cname" : "Christmas Island", "url" : "" },
    // { "cname" : "Norfolk Island", "url" : "" },
    // { "cname" : "Papua New Guinea", "url" : "" },
    // { "cname" : "New Zealand", "url" : "" },
    // { "cname" : "Fiji", "url" : "" },
    // { "cname" : "Solomon Islands", "url" : "" },
    // { "cname" : "Micronesia", "url" : "" },
    // { "cname" : "Vanuatu", "url" : "" },
    // { "cname" : "Kiribati", "url" : "" },
    // { "cname" : "Tonga", "url" : "" },
    // { "cname" : "Marshall Islands", "url" : "" },
    // { "cname" : "Palau", "url" : "" },
    // { "cname" : "Nauru", "url" : "" },
    // { "cname" : "Tuvalu", "url" : "" },
    // { "cname" : "Niue", "url" : "" },
    // { "cname" : "Tokelau", "url" : "" },
    // { "cname" : "Wallis and Futuna", "url" : "" },
    //     ]
    this.australia_Countries = [
      "Christmas Island",
      "Norfolk Island",
      "Papua New Guinea",
      "New Zealand",
      "Fiji",
      "Solomon Islands",
      "Micronesia",
      "Vanuatu",
      "Kiribati",
      "Tonga",
      "Marshall Islands",
      "Palau",
      "Nauru",
      "Tuvalu",
      "Niue",
      "Tokelau",
      "Wallis and Futuna"
    ]
  }
  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 3000,
      panelClass: "text-white",
      verticalPosition: "top",
    });
  }

  toggleSubMenu(list: any) {
    console.log(list.categoryName)

    if (list.categoryName === 'Asia') {
      this.isAsiaOpen = true;
      this.isAfricaOpen = false;
      this.isNorthAmeOpen = false;
      this.isSouthAmeOpen = false;
      this.isAntarctiOpen = false;
      this.isEuropeOpen = false;
      this.isAustraliaOpen = false;
      // this.isSideNavOpen = !this.isSideNavOpen
    }
    if (list.categoryName === 'Africa') {
      this.isAfricaOpen = true;
      this.isNorthAmeOpen = false;
      this.isSouthAmeOpen = false;
      this.isAntarctiOpen = false;
      this.isEuropeOpen = false;
      this.isAustraliaOpen = false;
      this.isAsiaOpen = false;
      // this.isSideNavOpen = !this.isSideNavOpen
    }
    if (list.categoryName === 'North America') {
      this.isNorthAmeOpen = true;
      this.isSouthAmeOpen = false;
      this.isAntarctiOpen = false;
      this.isEuropeOpen = false;
      this.isAustraliaOpen = false;
      this.isAsiaOpen = false;
      this.isAfricaOpen = false;
      // this.isSideNavOpen = !this.isSideNavOpen
    }
    if (list.categoryName === 'South America') {
      this.isSouthAmeOpen = true;
      this.isAntarctiOpen = false;
      this.isEuropeOpen = false;
      this.isAustraliaOpen = false;
      this.isAsiaOpen = false;
      this.isAfricaOpen = false;
      this.isNorthAmeOpen = false;
    }
    if (list.categoryName === 'Antarctica') {
      this.isAntarctiOpen = true;
      this.isEuropeOpen = false;
      this.isAustraliaOpen = false;
      this.isAsiaOpen = false;
      this.isAfricaOpen = false;
      this.isNorthAmeOpen = false;
      this.isSouthAmeOpen = false;
      // this.isSideNavOpen = !this.isSideNavOpen
    }
    if (list.categoryName === 'Europe') {
      this.isEuropeOpen = true;
      this.isAustraliaOpen = false;
      this.isAsiaOpen = false;
      this.isAfricaOpen = false;
      this.isNorthAmeOpen = false;
      this.isSouthAmeOpen = false;
      this.isAntarctiOpen = false;
      // this.isSideNavOpen = !this.isSideNavOpen
    }
    if (list.categoryName === 'Australia') {
      this.isAustraliaOpen = true;
      this.isAsiaOpen = false;
      this.isAfricaOpen = false;
      this.isNorthAmeOpen = false;
      this.isSouthAmeOpen = false;
      this.isAntarctiOpen = false;
      this.isEuropeOpen = false;
      // this.isSideNavOpen = !this.isSideNavOpen
    }

  }

  navigate1(category: any) {
    this.currentPath = category;
  }

  navigatecountrydoc(category: any) {
    this.loading = true;
    this.loginService.showLoader.next(true);
    this.isSideNavOpen = !this.isSideNavOpen
    console.log("submenu navigations", category)
    if (category != '') {
    this.loginService.callSearchClear.next(true);
      this.router.navigateByUrl('/234_CountriesInformation/country/' + category);
      this.loading = false;
      this.loginService.showLoader.next(false);
      if (this.isHighlighted(category)) {
        this.highlightedCountry = null;
      } else {
        this.highlightedCountry = category;
      }
    }
    else {
      this.router.navigateByUrl('/home')
    }
  }


  isHighlighted(country: string): boolean {
    return this.highlightedCountry === country;
  }

  toggleHighlight(country: string): void {
    if (this.isHighlighted(country)) {
      this.highlightedCountry = null;
    } else {
      this.highlightedCountry = country;
    }
  }

  isSpecialCountry(country: string): boolean {
    return [
      'Central Asia',
      'East Asia',
      'North Asia',
      'South Asia',
      'Southeast Asia',
      'West Asia',
      'North Africa',
      'West Africa',
      'Central Africa',
      'East Africa',
      'Southern Africa',
      'Northern America',
      'Central America',
      'Caribbean',
      'Narrow Definition'
    ].includes(country);
  }
}
