import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable, BehaviorSubject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class LoginService {
  public baseUrl: string = "https://api.softexer.com";
  public userID: string = "";
  showLoader = new BehaviorSubject(false);
  public callSearchClear = new BehaviorSubject(false);

  constructor() { }

 

}
