import { Component, OnInit,Output,EventEmitter  } from "@angular/core";
import { MatDialog, MatSnackBar } from "@angular/material";
import { Router, NavigationEnd, ActivatedRoute } from "@angular/router";
import { LoginService } from "src/app/services/login.service";
import { HttpErrorResponse } from "@angular/common/http";
import { JobsService } from "src/app/services/jobs.service";
import { PushMessagingService } from "src/app/services/push-messaging.service";
import { FormGroup, FormBuilder } from "@angular/forms";
@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.css"],
})
export class HeaderComponent implements OnInit {
  @Output() clearSearch: EventEmitter<any> = new EventEmitter();

  user: any;
  loggedIn: boolean;
  obj: any;
  badgeDisplay: boolean = false;
  userId: any;
  previewUrl: any = "https://api.softexer.com/user.png";
  badgeCount: Number = 0;
  NavigationIndex: any;
  selectedHdr: any = "home"
  CurrencyList: Array<any> = [];
  isDropdownOpen: boolean = false;
  selectCImg: any;
  selectedText: any;
  // currencyData = currencyDataInfo;
  usercurrencyObj: any;
  isDropdownOpens: boolean;
  routeractivelink: string = "";
  searchcountryForm: FormGroup;
  searchQuery: string = '';
  AllCountryData: Array<any> = [];
  filteredCountries: any[] = [];
  showError: boolean = false;
  keyword: ['']
  constructor(
    private dialog: MatDialog,
    private router: Router,
    private loginService: LoginService,
    private snackBar: MatSnackBar,
    private jobService: JobsService,
    private pushService: PushMessagingService,
    private route: ActivatedRoute,
    // private authservice2: AuthService2
    private formBuilder: FormBuilder
  ) {
 
    this.loginService.callSearchClear.subscribe((val) => {
      if (val == true) {
        console.log("Munvar")
        this.clearSearchAndFilteredCountries();
      }
    });
    this.router.events.subscribe((evt) => {
      if (evt instanceof NavigationEnd) {
        let isUrl1 = "" + evt.url;
        const parts = isUrl1.slice(1).split('/');
        const validRoutes = ['home', 'browsejobs', 'Myjobs', 'Buy-Sell-Projects', 'howitworks', 'services', "login", "signup"];
        const routeIndex = parts.findIndex(part => validRoutes.includes(part));
        if (routeIndex !== -1) {
          const routeName = parts[routeIndex];
          const index = validRoutes.indexOf(routeName);
          if (index !== -1) {
            this.routeractivelink = routeName;
            this.selectedHdr = routeName;
          } else {
            this.routeractivelink = "";
            this.selectedHdr = "";
          }

        }
      }
    });

  }

  ngOnInit() {
   this.AllCountryData=[
     "Central Asia",
      "Kazakhstan",
      "Kyrgyzstan",
      "Tajikistan",
      "Uzbekistan",
      "East Asia",
      "China",
      "Japan",
      "North Korea",
      "South Korea",
      "Mongolia",
      "Taiwan",    
      "North Asia",  
      "Russia",
      "South Asia",
      "Afghanistan",
      "Bangladesh",
      "Bhutan",
      "India",
      "Iran",
      "Maldives",
      "Nepal",
      "Pakistan",
      "Sri Lanka",
      "Southeast Asia",
      "Brunei",
      "Cambodia",
      "East Timor",
      "Indonesia",
      "Laos",
      "Malaysia",
      "Myanmar",
      "Philippines",
      "Singapore",
      "Thailand",
      "Vietnam",
      "West Asia",
      "Armenia",
      "Azerbaijan",
      "Bahrain",
      "Cyprus",
      "Georgia",
      "Iraq",
      "Israel",
      "Jordan",
      "Kuwait",
      "Lebanon",
      "Oman",
      "Qatar",
      "Saudi Arabia",
      "Syria",
      "Turkey",
      "United Arab Emirates",
      "Yemen",
      "Macao",
      "British Indian Ocean Territory",
      "Cocos Islands",
      "Guam",
      "Hong Kong",
      "North Africa",
      "Algeria",
      "Egypt",
      "Libya",
      "Morocco",
      "Sudan",
      "Tunisia",
      "Western Sahara",
      "West Africa",
      "Benin",
      "Burkina Faso",
      "Gambia",
      "Ghana",
      "Guinea",
      "Guinea-Bissau",
      "Liberia",
      "Mali",
      "Mauritania",
      "Niger",
      "Nigeria",
      "Senegal",
      "Sierra Leone",
      "Togo",
      "Central Africa",
      "Angola",
      "Cameroon",
      "Central African Republic",
      "Chad",
      "Congo, Democratic Republic",
      "Republic of the Congo",
      "Equatorial Guinea",
      "Gabon",
      "East Africa",
      "Burundi",
      "Comoros",
      "Djibouti",
      "Eritrea",
      "Ethiopia",
      "Kenya",
      "Madagascar",
      "Malawi",
      "Mauritius",
      "Mozambique",
      "Rwanda",
      "Seychelles",
      "Somalia",
      "South Sudan",
      "Tanzania",
      "Uganda",
      "Southern Africa",
      "Botswana",
      "Eswatini",
      "Lesotho",
      "Namibia",
      "South Africa",
      "Zambia",
      "Zimbabwe",
      "Saint Helena , Ascension and Tristan da Cunha",
      "Cameroon",
       "Northern America",
      "Canada",
      "Saint Pierre and Miquelon",
      "Bermuda",
      "Central America",
      "Belize",
      "Costa Rica",
      "El Salvador",
      "Guatemala",
      "Honduras",
      "Nicaragua",
      "Panama",
      "Caribbean",
      "Antigua and Barbuda",
      "Barbados",
      "Cuba",
      "Dominica",
      "Dominican Republic",
      "Grenada",
      "Haiti",
      "Jamaica",
      "Saint Kitts and Nevis",
      "Saint Lucia",
      "Saint Vincent and the Grenadines",
      "Trinidad and Tobago",
      "Narrow Definition",
      "Mexico",
      "United States of America",
      "Sint Maarten",
      "Turks and Caicos Islands",
      "Puerto Rico",
      "Montserrat",
      "French Polynesia",
      "Northern Mariana Islands",
      "Anguilla",
      "The Bahamas",
      "Cayman Islands",
      "Guadeloupe",
      "British Virgin Islands",
      "American Samoa",
      "Aruba",
      "Bonaire",
      "Curacao",
      "Saint Barthelemy",
      "Argentina",
      "Bolivia",
      "Brazil",
      "Chile",
      "Colombia",
      "Ecuador",
      "Guyana",
      "Paraguay",
      "Peru",
      "Suriname",
      "Uruguay",
      "Venezuela",
      "Falkland Islands",
      "Pitcairn Islands",
      "French Southern and Antarctic Lands",
      "Albania",
      "Andorra",
      "Austria",
      "Belarus",
      "Belgium",
      "Bosnia and Herzegovina",
      "Bulgaria",
      "Croatia",
      "Czech",
      "Denmark",
      "Estonia",
      "Finland",
      "France",
      "Germany",
      "Greece",
      "Hungary",
      "Iceland",
      "Italy",
      "Kosovo",
      "Latvia",
      "Liechtenstein",
      "Lithuania",
      "Luxembourg",
      "Malta",
      "Moldova",
      "Monaco",
      "Montenegro",
      "Netherlands",
      "North Macedonia",
      "Norway",
      "Poland",
      "Portugal",
      "Romania",
      "San Marino",
      "Serbia",
      "Slovakia",
      "Slovenia",
      "Spain",
      "Sweden",
      "Switzerland",
      "Ukraine",
      "United Kingdom",
      "Gibraltar",
      "Faroe Islands",
      "Greenland",
      "Scotland",
      "Republic of Ireland",
      "Vatican City",
        "Christmas Island",
      "Norfolk Island",
      "Papua New Guinea",
      "New Zealand",
      "Fiji",
      "Solomon Islands",
      "Micronesia",
      "Vanuatu",
      "Kiribati",
      "Tonga",
      "Marshall Islands",
      "Palau",
      "Nauru",
      "Tuvalu",
      "Niue",
      "Tokelau",
      "Wallis and Futuna"
   ]
    this.user = JSON.parse(localStorage.getItem("user")) || null;
    if (this.user && this.user.userID && this.user.isProfileUpdate) {
      this.loggedIn = true;
      // this.fetchData(this.user.userID);
      // this.getNotificationCount();
     
      this.loginService.userID = this.user.userID;
    } else {
      this.loggedIn = false;
      // this.loginService.checkIsLoggedIn.next(false);
      localStorage.clear();
    }
    // this.searchcountryForm = this.fb.group({
    //   searchQuery: [""],
    // });
    this.searchcountryForm = this.formBuilder.group({
      keyword: [''] // You can set initial values or validators here
    });
   
  }

  inputValueChanged(value: string) {
    if (value.length >= 2) {
        this.searchCategory();
    }
    else{
      this.filteredCountries = [];
    }
}
  clearSearchAndFilteredCountries() {
    this.searchcountryForm.reset();
    this.filteredCountries = [];
    this.clearSearch.emit();
    this.loginService.callSearchClear.next(false);
  }
  searchTextFilter() {
    let searchText: string = this.searchcountryForm.get('searchText').value;
    // Convert the first letter to uppercase
    if (searchText.length > 0) {
      searchText = searchText.charAt(0).toUpperCase() + searchText.slice(1);
    }
    this.searchQuery = searchText;

    console.log(this.searchQuery)

    if(this.searchQuery!=''){    
      this.router.navigateByUrl('/234_CountriesInformation/country/' + this.searchQuery);       
    }
    
  }


  searchCategory() {
    let mndata =this.AllCountryData
    const originalAllCountryData =this.AllCountryData;
    const keyword = this.searchcountryForm.get('keyword').value.toLowerCase();
  console.log(keyword)
    if (keyword === '') {
      console.log("REshma")
      // this.AllCountryData =mndata 
      this.filteredCountries = [];
      // console.log(mndata)
      this.showError = false;
    } else {
      console.log("2")
      this.filteredCountries = originalAllCountryData.filter(country =>
        country.toLowerCase().includes(keyword)
      );
  
      if (this.filteredCountries.length === 0) {
        console.log("3")
        // No matching countries found
        this.showError = true;
      } else {
        console.log("4")
        // Matching countries found
        this.showError = false;
        // Display the filtered results
        this.AllCountryData = mndata;
      }
    }
  }
 
  showService(item: any) {
    if(item!=''){ 
      this.router.navigateByUrl('/234_CountriesInformation/country/' + item);   
      this.filteredCountries = [];    
      this.searchcountryForm.reset();
    }
  }

  setStyles(selectModule) {
    let style;
    let nm = selectModule;
    if (this.selectedHdr == selectModule) {
      style = {
        "text-decoration-color": "hsl(192, 96%, 40%)",
        "color": "hsl(192, 96%, 40%)",
      }
    }
    return style;
  }

  


}
