

export const environment = {
  production: false,
  firebase: {    
     // softexer-firebse
    apiKey: "AIzaSyCZilakFhfl15yOVg36k1PtxNVd-3ERC3E",
    authDomain: "httpssoftexercom.firebaseapp.com",
    projectId: "httpssoftexercom",
    storageBucket: "httpssoftexercom.appspot.com",
    messagingSenderId: "454915249681",
    appId: "1:454915249681:web:1eb548136e65c7a51003b0",
    measurementId: "G-HSV4SG7FCR"

    //sultana-firebse
    // apiKey: "AIzaSyA4fhsVS8rchryOC_35Vu_vKEsLBmuWwPk",
    // authDomain: "socialmedialogin-94a2c.firebaseapp.com",
    // projectId: "socialmedialogin-94a2c",
    // storageBucket: "socialmedialogin-94a2c.appspot.com",
    // messagingSenderId: "962000574291",
    // appId: "1:962000574291:web:852bfbc35883fe8c6633a6",
    // measurementId: "G-MDFPQSY5M9"
  },
  stripe: {
    //serverKey: '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',
   // serverKey :'sk_test_51LmY9lSGbGBd6nlhXbn4GZRCPWFYiv7HfltAVJnF6QEvKbZTSxCewU36TJgc75cC256nvJDVFBL0rEPLUnYXZpud00vWZekHhc'
    publicKey :'pk_live_51LmY9lSGbGBd6nlhlIVPwLTTS2T7ws3xCSH2l0Mizo17mBU7oIdPrC2VuS5GEtzQB3eOAlyIRn9xMGGbgFgeIflz00zS4pGlox'
   // publicKey : 'pk_test_51LmY9lSGbGBd6nlhCLCaNfpmdYu6xQHe9mMCHstoolNxrtWiZChXEKjYRdoc1RLRTQs4XcOE814URI9ee5K15ISE00V2AYw9WG'
  }
};
