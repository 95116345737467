import { HttpErrorResponse } from "@angular/common/http";
import { Component, OnInit, ChangeDetectorRef } from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import { MatSnackBar } from "@angular/material";
import { JobsService } from "src/app/services/jobs.service";
import { LoginService } from "src/app/services/login.service";

@Component({
  selector: "app-footer",
  templateUrl: "./footer.component.html",
  styleUrls: ["./footer.component.css"],
})
export class FooterComponent implements OnInit {
  splitedCategories: Array<any> = [];
  loggedIn: boolean;
  selectedCategory: any;
  selectitem: string = ""
  isUrl: string = "";
  categoriesList: Array<any> = [];
  selectedtextcolor: string = "";
  constructor(
    private loginService: LoginService,
    private jobService: JobsService,
    private snackBar: MatSnackBar,
    private router: Router,
    private changeDetectorRef: ChangeDetectorRef
  ) {
    // this.loginService.checkIsLoggedIn.subscribe((val) => {
    //   if (!val) {
    //     this.loggedIn = false;
    //   } else {
    //     this.loggedIn = true;
    //   }
    // });
    this.router.events.subscribe((evt) => {
      if (evt instanceof NavigationEnd) {
        this.isUrl = "" + evt.url;
        this.selectedtextcolor = this.isUrl;
      }
    });
  }

  ngOnInit() {
   
  }

 


}



