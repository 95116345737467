import { ChangeDetectorRef, Component, EventEmitter, OnInit, Output, ViewEncapsulation } from "@angular/core";
import { NgbCarouselConfig } from "@ng-bootstrap/ng-bootstrap";
import { LoginService } from "src/app/services/login.service";
import { ActivatedRoute, Router } from "@angular/router";
import { JobsService } from "src/app/services/jobs.service";
import { HttpErrorResponse } from "@angular/common/http";
import { Title, Meta } from '@angular/platform-browser';
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { MatDialog, MatSnackBar } from "@angular/material";

@Component({
  selector: "app-home",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.css"],
  encapsulation: ViewEncapsulation.None,
})
export class HomeComponent implements OnInit {
  categoryhours = 43;
  categoryname = 'SI/PI Simulation'
  user: any;
  isLoggedIn: boolean = false;
  searchTermForm: FormGroup;
  refferObj: any;
  popularProjects: Array<any> = [];
  splitedpopularProjects: Array<any> = [];
  categoriesList: Array<any> = [];
  categories10List: Array<any> = [];
  shareUrl: any = "https://softexer.com/#/home";
  shareTaskTitle: any = "Softexer";
  isSocialBtnsHidden: boolean = false;
  baseUrl: string = "";
  selectedCat: Array<any> = [];
  splitedCategories: Array<any> = [];
  isUserContacted: boolean = false;
  profileAltTag: string = "users on softexer";
  showRemainingContent = false;
  usercurrencyObj: any;
  dollerChangeValue: string = "USD";
  loading: boolean = false;
  @Output() isContacted = new EventEmitter<{ isApiCompleted: boolean }>();
  isImageUploaded: boolean = false;
  fileName: string = "";
  numberofservice: Array<any> = []
  satrtvalue: number = 0;
  maxvalue: number = 60;
  inrvalue: number = 1;
  seemores: any = {
    "categoryId": "Seemore",
    "categoryName": "See More",
    "image": "/assets/softexernew/seemore.png",
  }
  displayedCategories: Array<any> = [];
  contactForm: FormGroup
  constructor(
    private config: NgbCarouselConfig,
    private loginService: LoginService,
    private router: Router,
    private jobService: JobsService,
    private fb: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private snackBar: MatSnackBar,
    private dialog: MatDialog,
    private metaTagService: Meta,
    private title: Title,
    private cd: ChangeDetectorRef
  ) {
    // this.loginService.checkIsLoggedIn.subscribe((val) => {
    //   if (!val) {
    //     this.isLoggedIn = false;
    //   } else {
    //     this.isLoggedIn = true;
    //   }
    // });
    // this.loginService.changeCountryCurrency.subscribe((val) => {
    //   if (val) {
    //     // this.changCurrency();
    //   }
    // })
  }

  ngOnInit() {
    this.title.setTitle("Trdfin | 234 countries Information")
    this.metaTagService.addTag({ name: 'H1', content: "234 Countries Information" });
    this.metaTagService.addTag({ name: 'description', content: "Discover comprehensive information about 234 countries with Trdfin. Explore detailed data, including demographics, economy, geography, and more. Enhance your knowledge and understanding of nations worldwide with Trdfin's extensive country profiles." });
    // this.metaTagService.addTag({ name: 'keywords', content: 'Offshore outsourcing, Freelancers, android developers, SEO, Internet Marketing, Technical writers, web designers, content writers, Data entry, Amazon web services, Industrial design, fabrication, Assembly, Firmware, cloud computing, ios developers, PCB layout design, circuit designing, Social media marketing.' }),
      this.baseUrl = this.jobService.baseUrl;
    // this.getCategories();
    this.activatedRoute.queryParamMap.subscribe((params) => {
      this.refferObj = { ...params };
    });

    this.searchTermForm = this.fb.group({
      keyword: ["", Validators.required],
    });


  }


  navigateTdrfinService(){
    
  }

 
}
