import {
  Component,
  OnInit,
  Inject,
  ViewEncapsulation,
  ChangeDetectorRef,
} from "@angular/core";
// import {  Renderer2, Sanitizer } from "@angular/core";
import { ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
// import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { LoginService } from "src/app/services/login.service";
@Component({
  selector: 'app-asia-kazakhstan',
  templateUrl: './asia-kazakhstan.component.html',
  styleUrls: ['./asia-kazakhstan.component.css']
})
export class AsiaKazakhstanComponent implements OnInit {
  documentId: string;
  documentLink: string;
  AllCountryInfo: Array<any> = [];
  routeSub: any;
  id:any;
  loading:boolean = false;
  
  constructor(private activatedRoute: ActivatedRoute,
    private loginService:LoginService ) { }

  ngOnInit() {  
    this.loading = true;
    this.loginService.showLoader.next(true);
    console.log("Munvar")
    this.routeSub = this.activatedRoute.params.subscribe((params) => {
      this.id = params["id"];
      console.log(this.id )  
        this.loading = false;  
    });
    this.loginService.showLoader.next(false);
    
    
  }
  

}
