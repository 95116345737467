import { BrowserModule, Title } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { HttpClientModule } from "@angular/common/http";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";

import {
  MatToolbarModule,
  MatIconModule,
  MatButtonModule,
  MatCardModule,
  MatTabsModule,
  MatFormFieldModule,
  MatInputModule,
  MatDialogModule,
  MatRadioModule,
  MatTableModule,
  MatPaginatorModule,
  MatSortModule,
  MatCheckboxModule,
  MatSnackBarModule,
  MatChipsModule,
  MatDatepickerModule,
  MatNativeDateModule,
  MatSidenavModule,
  MatListModule,
  MatButtonToggleModule,
  MatSelectModule,
  MatSliderModule,
  MatSlideToggleModule,
  MatMenuModule,
  MatRippleModule,
  MatStepperModule,
  MatBottomSheetModule,
  MatBadgeModule,
  MatTooltipModule
} from "@angular/material";
import { HeaderComponent } from "./components/header/header.component";
import { HomeComponent } from "./components/home/home.component";
import { GroupServicesComponent } from "./components/TrdFinServices/group-services/group-services.component";
import { AsiaKazakhstanComponent } from './components/TrdFinServices/asia-kazakhstan/asia-kazakhstan.component';

import { CommonModule } from '@angular/common';
import { FooterComponent } from "./components/footer/footer.component";
import { SidebarComponent } from "./components/sidebar/sidebar.component";
import { CalendarModule, DateAdapter } from "angular-calendar";
import { adapterFactory } from "angular-calendar/date-adapters/date-fns";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { ReactiveFormsModule, FormsModule } from "@angular/forms";


import { AngularFireMessagingModule } from "@angular/fire/messaging";
import { AngularFireDatabaseModule } from "@angular/fire/database";
import { AngularFireAuthModule } from "@angular/fire/auth";
import { AngularFireModule } from "@angular/fire";
import { PushMessagingService } from "./services/push-messaging.service";
import { environment } from "../environments/environment";
import { AsyncPipe } from "../../node_modules/@angular/common";
import { ScrollingModule } from "@angular/cdk/scrolling";
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { RouterModule } from '@angular/router';
import { AngularFirestoreModule } from '@angular/fire/firestore'; 



@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    HomeComponent,
    FooterComponent,
    SidebarComponent,
    GroupServicesComponent,
    AsiaKazakhstanComponent,
    
  ],
  entryComponents: [ SidebarComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatToolbarModule,
    MatSelectModule,
    ReactiveFormsModule, 
    HttpClientModule,
    MatMenuModule,
    FormsModule,
    MatIconModule,
    ScrollingModule,
    MatStepperModule,
    MatButtonModule,
    MatCheckboxModule,
    MatBottomSheetModule,
    MatSlideToggleModule,
    MatBadgeModule,
    NgbModule,
    MatListModule,
    MatCardModule,
    MatSliderModule,
    MatRadioModule,
    MatPaginatorModule,
    MatButtonToggleModule,
    MatTableModule,
    MatSidenavModule,
    MatSortModule,    
    MatDialogModule,
    MatChipsModule,
    MatCardModule,
    MatTabsModule,
    MatTooltipModule,
    MatFormFieldModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatRippleModule,
    MatInputModule,   
    MatSnackBarModule,    
    AngularFireDatabaseModule,
    AngularFireAuthModule,
    AngularFireMessagingModule,    
    MatProgressBarModule,
    CommonModule,
    AngularFireModule.initializeApp(environment.firebase),    
    AngularFirestoreModule, // imports firebase/firestore, only needed for database features
    AngularFireAuthModule, // imports firebase/auth, only needed for auth features
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory,
    }),    
  ],
  providers: [    
    PushMessagingService,
    AsyncPipe,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
