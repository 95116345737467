import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable, BehaviorSubject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class JobsService {
  public isJobPosted: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public isAfterCancelJob: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public baseUrl = "https://api.softexer.com";
  constructor(private http: HttpClient) {}
  checkStatus(data): Observable<any> {
    const myheader = new HttpHeaders().set(
      "Content-Type",
      "application/x-www-form-urlencoded"
    );
    let urlSearchParams = new URLSearchParams();
    urlSearchParams.append("Amount", data.Amount);
    urlSearchParams.append("CurrencyCode", data.CurrencyCode);
    urlSearchParams.append("HashValue", data.HashValue);
    urlSearchParams.append("PaymentID", data.PaymentID);
    urlSearchParams.append("PymtMethod", data.PymtMethod);
    urlSearchParams.append("ServiceID", data.ServiceID);
    urlSearchParams.append("TransactionType", data.TransactionType);
    let body = urlSearchParams.toString();

    return this.http.post(
      "https://securepay.e-ghl.com/IPG/payment.aspx",
      body,
      { headers: myheader, responseType: "text" }
    );
  }
  paytmTransactionStatus(id): Observable<any> {
    return this.http.post(
      `${this.baseUrl}/api/projects/getPaytmTxnTokenstatus`,
      id
    );
  }
  payForTopTen(data, token): Observable<any> {
    return this.http.post(`${this.baseUrl}/api/projects/PayTopTen`, data, {
      headers: { softexer: token },
    });
  }
  payForProject(data, token): Observable<any> {
    return this.http.post(`${this.baseUrl}/api/projects/BuyProject`, data, {
      headers: { softexer: token },
    });
  }
  payForProfileView(data: any,token): Observable<any> {
    return this.http.post(`${this.baseUrl}/api/customer/payprofleview`, data, {
      headers: { softexer: token },
    });
  }

  getPaytmToken(data, token): Observable<any> {
    return this.http.post(
      this.baseUrl + `/api/projects/getPaytmTxnToken`,
      data,
      { headers: { softexer: token } }
    );
  }
  manualPaymentApi(data, token): Observable<any> {
    return this.http.post(`${this.baseUrl}/api/hire/transactionDetails`, data, {
      headers: { softexer: token },
    });
  }
  ProfileViewPay(data, token): Observable<any> {
    return this.http.post(`${this.baseUrl}/api/customer/payprofleview`, data, {
      headers: { softexer: token },
    });
  }
  PayProjectTopTen(data, token): Observable<any> {
    return this.http.post(`${this.baseUrl}/api/projects/PayTopTen`, data, {
      headers: { softexer: token },
    });
  }
  browsJobs(data, token): Observable<any> {
    return this.http.post(this.baseUrl + "/api/postjob/fetch_all", data, {
      headers: { softexer: token },
    });
  }
  updateProject(data, token): Observable<any> {
    return this.http.put(this.baseUrl + "/api/projects/updateProject", data, {
      headers: { softexer: token },
    });
  }

  messageReadUpdate(data, token): Observable<any> {
    return this.http.put(
      `${this.baseUrl}/api/offers/isReadMessageUpdate`,
      data,
      { headers: { softexer: token } }
    );
  }
  searchTermJobs(data, token): Observable<any> {
    return this.http.post(this.baseUrl + "/api/customer/task", data, {
      headers: { softexer: token },
    });
  }
  fetchAllProjects(data, token): Observable<any> {
    return this.http.post(
      this.baseUrl + "/api/projects/fetchAllProjects",
      data,
      {
        headers: { softexer: token },
      }
    );
  }
  AllProjectsFetch(): Observable<any> {
    return this.http.get(this.baseUrl + "/api/projects/fetchProjects");
  }
  fetchProjects(data, token): Observable<any> {
    return this.http.post(this.baseUrl + "/api/projects/viewProject", data, {
      headers: { softexer: token },
    });
  }
  fetchAllMyProjects(data, token): Observable<any> {
    return this.http.post(this.baseUrl + "/api/projects/myproject", data, {
      headers: { softexer: token },
    });
  }
  fetchAllMyBookings(data, token): Observable<any> {
    return this.http.post(this.baseUrl + "/api/projects/getBuyandSell", data, {
      headers: { softexer: token },
    });
  }
  
  browseCategory(): Observable<any> {
    return this.http.get(this.baseUrl + "/api/categories");
  }
  postJobs(data, token): Observable<any> {
    return this.http.post(this.baseUrl + "/api/postjob", data, {
      headers: { softexer: token },
    });
  }
  withDrawOffer(data, token): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        softexer: token,
      }),
      body: {
        postID: data.postID,
        offeredUserID: data.offeredUserID,
      },
    };
    return this.http.delete(this.baseUrl + "/api/offers/delete_offer", options);
  }
  replyToUser(data, token): Observable<any> {
    return this.http.post(
      this.baseUrl + "/api/postjob/replay_to_offereduser",
      data,
      { headers: { softexer: token } }
    );
  }
  partialHire(data, token): Observable<any> {
    return this.http.post(this.baseUrl + "/api/hire/partialBooking", data, {
      headers: { softexer: token },
    });
  }
  developerHire(data, token): Observable<any> {
    return this.http.post(this.baseUrl + "/api/customer/developerHire", data, {
      headers: { softexer: token },
    });
  }
  fullHire2(data, token): Observable<any> {
    return this.http.post(this.baseUrl + "/api/hire/taskers", data, {
      headers: { softexer: token },
    });
  }
  fullHire(data, token): Observable<any> {
    return this.http.post(this.baseUrl + "/api/hire/hireTasker", data, {
      headers: { softexer: token },
    });
  }
  deleteBooking(data, token): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        softexer: token,
      }),
      body: {
        bookingID: data.bookingID,
      },
    };
    return this.http.delete(this.baseUrl + "/api/hire/partialBooking", options);
  }
  deleteProject(data, token): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        softexer: token,
      }),
      body: {
        projectID: data.projectID,
      },
    };
    return this.http.delete(this.baseUrl + "/api/projects/deleteProject", options);
  }
  deleteTransction(data, token): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        softexer: token,
      }),
      body: {
        transactionID: data.transactionID,
      },
    };
    return this.http.delete(this.baseUrl + "/api/hire/partialBooking", options);
  }
  editPost(data, token): Observable<any> {
    return this.http.put(this.baseUrl + "/api/postjob/update", data, {
      headers: { softexer: token },
    });
  }
  cancelTask(data, token): Observable<any> {
    return this.http.put(this.baseUrl + "/api/postjob/taskCancel", data, {
      headers: { softexer: token },
    });
  }
  getMyTasks(data, token): Observable<any> {
    return this.http.post(this.baseUrl + "/api/postjob/get", data, {
      headers: { softexer: token },
    });
  }
  CheckBuyProjects(data,token) : Observable<any> {
    return this.http.post(this.baseUrl + "/api/projects/checkBuyProject", data, {
      headers: { softexer: token },
    });
  }
  getMyOfferedTasks(data, token): Observable<any> {
    return this.http.post(this.baseUrl + "/api/offers/getOfferedPosts", data, {
      headers: { softexer: token },
    });
  }
  getFilteredJobs(data, token): Observable<any> {
    return this.http.post(this.baseUrl + "/api/postjob/browsejob", data, {
      headers: { softexer: token },
    });
  }
  updatePhoto(data, token): Observable<any> {
    return this.http.put(this.baseUrl + "/api/customer/update", data, {
      headers: { softexer: token },
    });
  }
  updateMobileNumber(data, token): Observable<any> {
    return this.http.put(this.baseUrl + "/api/customer/updatemobileno", data, {
      headers: { softexer: token },
    });
  }
  reportTask(data, token) {
    return this.http.post(this.baseUrl + "/api/task/insert", data, {
      headers: { softexer: token },
    });
  }
  updateDob(data, token): Observable<any> {
    return this.http.put(this.baseUrl + "/api/customer/updatedob", data, {
      headers: { softexer: token },
    });
  }
  updateAddress(data, token): Observable<any> {
    return this.http.put(this.baseUrl + "/api/customer/updateaddress", data, {
      headers: { softexer: token },
    });
  }
  updateBankAccount(data, token): Observable<any> {
    return this.http.put(this.baseUrl + "/api/customer/updateaccount", data, {
      headers: { softexer: token },
    });
  }
  makeAnOffer(data, token): Observable<any> {
    return this.http.post(this.baseUrl + "/api/postjob/insertoffers", data, {
      headers: { softexer: token },
    });
  }
  updateAnOffer(data, token): Observable<any> {
    return this.http.put(this.baseUrl + "/api/offers/update_offer", data, {
      headers: { softexer: token },
    });
  }
  addComments(data, token): Observable<any> {
    return this.http.post(this.baseUrl + "/api/postjob/add_comment", data, {
      headers: { softexer: token },
    });
  }
  addProject(data, token): Observable<any> {
    return this.http.post(this.baseUrl + "/api/projects/addProject", data, {
      headers: { softexer: token },
    });
  }
  getProjectDetails(data: any, token: string): Observable<any> {
    return this.http.post(this.baseUrl + "/api/projects/viewProject", data, {
      headers: { softexer: token },
    });
  }
}
