import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { HomeComponent } from "./components/home/home.component";
//TrdFin
import { GroupServicesComponent } from "./components/TrdFinServices/group-services/group-services.component";
import { AsiaKazakhstanComponent } from "./components/TrdFinServices/asia-kazakhstan/asia-kazakhstan.component";

const routes: Routes = [
  { path: "home", component: HomeComponent },
  {
    path: "234_CountriesInformation",
    component: GroupServicesComponent,
    children: [    
      { path: "country/:id", component: AsiaKazakhstanComponent }
      
    ]
  
  },
  { path: "", redirectTo: "234_CountriesInformation", pathMatch: "full" },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule],
})
export class AppRoutingModule { }
